<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="opened"
  >
    <v-card class="expande-horizontal pa-1 wrap">
      <div class="expande-horizontal fonte px-1 pr-3 py-3">
        <v-flex xs12>
          <v-list>
            <v-list-item>
              <v-avatar class="mr-3" :color="$theme.secondary">
                <v-icon :color="$theme.primary">mdi-receipt-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Confirmação de Pagamento
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ get_invoice.charge.current_status | statusFilter }}
                  <v-chip small :color="$theme.primary" dark>
                    Vence
                    {{ $moment(get_invoice.payment_date).format("DD/MM/YY") }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="close">
                  <v-icon color="red">
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
      </div>
      <div class="expande-horizontal fonte column pl-6 pr-6">
        <h1 :style="`font-size: 18pt; :color: ${$theme.third}`">
          {{ $helper.formataSaldo(get_invoice.charge.value) }}
        </h1>
        <div>
          <v-icon>mdi-attachment</v-icon>
          <span class="py-6" :style="`color: ${$theme.secondary}`">
            Anexe um comprovante de pagamento abaixo
          </span>
        </div>
        <v-form ref="formPayCharge">
          <div class="expande-horizontal pt-2">
            <v-text-field
              outlined
              dense
              :color="$theme.secondary"
              label="Valor pago"
              placeholder="ex: 10,00"
              v-mask="[
                '#,##',
                '##,##',
                '###,##',
                '####,##',
                '#####,##',
                '######,##'
              ]"
              :rules="[v => !!v || 'Preencha aqui']"
              v-model="get_invoice.charge.value"
            ></v-text-field>
          </div>
          <div class="expande-horizontal pt-0">
            <v-select
              outlined
              dense
              :color="$theme.secondary"
              label="Selecione a forma de pagamento"
              placeholder="ex: Dinheiro"
              v-model="get_invoice.forma_de_pagamento"
              :items="[
                'DINHEIRO',
                'PIX',
                'CARTÃO DE CRÉDITO',
                'CARTÃO DE DÉBITO',
                'BOLETO'
              ]"
              :rules="[v => !!v || 'Preencha aqui']"
            ></v-select>
          </div>
        </v-form>
        <modal-send-archive />
      </div>
      <div class="expande-horizontal centraliza pa-3">
        <v-btn @click="submit" :color="$theme.third" dark block>
          <span>Confirmar Pagamento</span>
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalSendArchive from "../../shared/components/ModalSendArchiveFile.vue";
export default {
  props: ["pay"],
  data() {
    return {
      opened: false,
      get_invoice: {}
    };
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case "waiting_payment":
          return "Aguardando Pagamento";
        case "overdue":
          return "Atrasada";
      }
    }
  },
  components: { ModalSendArchive },
  methods: {
    submit() {
      if (this.$refs.formPayCharge.validate()) {
        this.pay(this.get_invoice);
        this.close();
      }
    },
    open(payload) {
      this.get_invoice = payload;
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  }
};
</script>
