<template>
  <layout style="background: #f5f5f5;" :title="$route.name" :options="options">
    <!-- <Filtros /> -->

    <div class="expande-horizontal wrap">
      <Desktop v-show="get_invoices.docs.length > 0" />
    </div>

    <EmptyList v-show="get_invoices.docs.length === 0" />
    <!-- <Pagination /> -->
    <!-- <ModalView ref="ViewInvoice" /> -->
    <ModalCreate ref="CreateInvoice" />
  </layout>
</template>

<script>
import Filtros from "../components/Filters.vue";
import Mobile from "../components/Mobile.vue";
import Desktop from "../components/Desktop.vue";
import Pagination from "../components/Pagination.vue";
import EmptyList from "../components/EmptyList.vue";
import ModalCreate from "../components/ModalCreate.vue";
// import ModalView from "../components/ModalView.vue";
import { EventBus } from "@/main.js";

import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      card: false,
      options: [
        {
          icon: "mdi-plus",
          nome: "Adicionar Conta",
          action: () => this.$refs.CreateInvoice.open()
        },
        {
          icon: "mdi-update",
          nome: "Atualizar",
          action: () => this.listar_invoices()
        }
      ]
    };
  },
  components: {
    Filtros,
    Mobile,
    Desktop,
    Pagination,
    EmptyList,
    // ModalView,
    ModalCreate
  },
  computed: {
    ...mapGetters(["get_invoices", "get_invoices_filtros", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["abre_modal_view_invoice", "listar_invoices"])
  },
  created() {
    this.listar_invoices();
    const self = this;
    EventBus.$on("open_create_invoice", () => {
      self.$refs.CreateInvoice.open();
    });
  }
};
</script>
