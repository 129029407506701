<template>
  <v-flex class="pa-3" :style="`background: ${$theme.background};`" xs12>
    <v-flex xs12>
      <div class="expande-horizontal wrap">
        <v-flex class="pr-3 pb-6" xs12 md4>
          <v-card>
            <v-flex class="pa-3" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="fonte fonte-little secondary-color fonte-bold">
                  {{ get_invoices.docs.length }}
                </span>
                <h5 class="fonte">Contas</h5>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex class="pr-3 pb-6" xs12 md4>
          <v-card>
            <v-flex class="pa-3" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="fonte fonte-little red--text fonte-bold">
                  {{ $helper.formataSaldo(get_invoices.total_a_pagar) }}
                </span>
                <h5 class="fonte">Total de parcelas a pagar</h5>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex class="pr-0 pb-6" xs12 md4>
          <v-card>
            <v-flex class="pa-3" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="fonte fonte-little green--text fonte-bold">
                  {{ $helper.formataSaldo(get_invoices.total_a_receber) }}
                </span>
                <h5 class="fonte">Total de parcelas a Receber</h5>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
      </div>
    </v-flex>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item, i) in get_invoices.docs"
        :key="`invoice-${i}`"
      >
        <v-expansion-panel-header class="fonte pa-0 pr-3">
          <div class="expande-horizontal wrap">
            <v-flex xs12 md1>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon
                    class="mr-3"
                    size="16"
                    v-if="item.type === 'to_pay'"
                    color="red"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon class="mr-3" size="16" v-else color="green"
                    >mdi-arrow-down</v-icon
                  >
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 10pt;">
                      {{ item.type | typeFilter }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      {{ item.status | typeStatus }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md4>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon v-if="item" size="15" class="mr-2"
                    >mdi-cash-register</v-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title
                      :style="`font-size: 10pt; color: ${$theme.secondary}`"
                      class="font-weight-bold"
                    >
                      {{ item.expense_type | expenseFilter }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon size="15" class="mr-2">mdi-cash</v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="green--text"
                      style="font-size: 10pt;"
                    >
                      {{ item.installment ? `${item.installment} x ` : "" }}
                      {{ $helper.formataSaldo(item.value) }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      {{ item.recurrent_type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon size="15" class="mr-2">mdi-flag</v-icon>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 10pt;">
                      {{
                        item.charges
                          ? item.charges.filter(
                              i => i.current_status === "paid"
                            ).length
                          : "antiga"
                      }}
                      {{ item.charges ? ` de ${item.charges.length}` : "" }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      Parcelas pagas
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="expande-horizontal">
            <v-flex xs12>
              <v-list
                v-if="item.charges"
                class="pr-1"
                dense
                color="transparent"
                style="overflow: auto;"
              >
                <v-list-item>
                  <v-list-item-content> </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      x-small
                      outlined
                      color="red"
                      @click="excluir_conta_dialog(item)"
                      >Excluir</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  class="mb-2 pt-1 pb-1"
                  :key="`${i}-installment`"
                  v-for="(installment, i) in item.charges"
                  style="border-radius: 6px; background: #f2f2f2;"
                >
                  <v-avatar
                    class="fonte white--text mr-3 font-weight-bold"
                    dark
                    :color="
                      installment.current_status === 'overdue' ? 'red' : 'green'
                    "
                  >
                    {{ installment.installment }}
                  </v-avatar>
                  <v-list-item-content class="fonte">
                    <v-list-item-title
                      class="fonte fonte-mini fonte-bold green--text"
                    >
                      {{ $helper.formataSaldo(installment.value) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="installment.current_status === 'paid'"
                      class="font-weight-bold"
                    >
                      Esta parcela foi paga no dia
                      {{
                        $moment(
                          installment.status.filter(i => i.status === "paid")[0]
                            .data
                        ).format("DD/MM/YYYY")
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else class="font-weight-bold">
                      Vence em
                      {{
                        $moment(installment.payment_date).format("DD/MM/YYYY")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="installment.current_status !== 'paid'"
                  >
                    <v-btn
                      class="fonte text-capitalize"
                      small
                      rounded
                      dark
                      @click="openPayInvoiceCharge(installment, item)"
                      :color="
                        installment.current_status === 'overdue'
                          ? 'red'
                          : 'green'
                      "
                    >
                      {{
                        installment.current_status === "overdue"
                          ? "Atrasada, pagar!"
                          : "Pagar"
                      }}
                      <v-icon class="ml-2" size="19"
                        >mdi-check-circle-outline</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <modal-pay-charge ref="modalPayCharge" :pay="pay" />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// openPayInvoiceCharge
import ModalPayCharge from "../components/ModalPayCharge.vue";
export default {
  computed: {
    ...mapGetters(["get_invoices"])
  },
  components: {
    ModalPayCharge
  },
  filters: {
    expenseFilter(value) {
      switch (value) {
        case "personal_expense":
          return "Despesa de Pessoal";
        case "contracted_services":
          return "Despesa Serviços Contratados";
        case "sale_expense":
          return "Despesa com Vendas";
        case "driver_expense":
          return "Despesa com Veículos";
        case "tax_expense":
          return "Despesa Tributária";
        case "occupancy_expense":
          return "Despesa com Ocupação";
        default:
          return "A Receber";
      }
    },
    typeFilter(value) {
      switch (value) {
        case "to_pay":
          return "A Pagar";
        case "to_receive":
          return "A Receber";
        default:
          return "Fatura";
      }
    },
    typeStatus(value) {
      switch (value) {
        case "scheduled":
          return "Agendada";
        case "overdue":
          return "Atrasada";
        default:
          return "Agendada";
      }
    }
  },
  methods: {
    ...mapActions([
      "abre_modal_view_invoice",
      "createConfirmAction",
      "pay_invoice_charge"
    ]),
    openPayInvoiceCharge(charge, invoice) {
      this.$refs.modalPayCharge.open({
        invoice: invoice,
        charge: charge
      });
    },
    pay(invoice) {
      this.pay_invoice_charge(invoice);
    },
    excluir_conta_dialog(item) {
      let text = "Deseja remover esta conta?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_invoice",
        action_value: item
      });
    }
  }
};
</script>
